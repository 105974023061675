
import { Options, Vue } from 'vue-class-component';
import { ElectricityEmissionClient, BagEstimationClient, GarmentClient } from '@/services/Services';
import ComponentsOrigins from './components/componentsOrigins.vue';
import * as OM from '@/Model';

@Options({
    components: {
        ComponentsOrigins
    }
})
export default class ComponentsMaterial extends Vue {

    estimationIdentifier: string = "";

    bagData: OM.BagData = new OM.BagData();
    bagComposition: OM.BagSelectedComposition = new OM.BagSelectedComposition();

    allCountries: OM.TextIdentifier[] = [];
    allMaterialCategories: string[] = [];

    loaded: boolean = false;
    
    created() {
        this.estimationIdentifier = this.$route.params.estimationIdentifier.toString();
        this.init();
    }

    init() {
        Promise.all([
            BagEstimationClient.getEstimationBagData(this.estimationIdentifier),
            ElectricityEmissionClient.getCountries(false)
        ])
        .then(xs => {
            this.bagData = xs[0];
            this.allCountries = xs[1];

            Promise.all([
                BagEstimationClient.getBagComposition(this.bagData.garment.identifier, this.estimationIdentifier, false),
                GarmentClient.getAllBagAvailablesMaterialCategoriesFromConfig(this.bagData.garment.identifier)
            ])
            .then(ys => {
                this.bagComposition = ys[0];
                this.allMaterialCategories = ys[1];

                setTimeout(() => {
                    this.loaded = true;
                }, 100);
            })
        })
    }

    get formattedData() {
        let dataWithIndent = this.bagComposition.percentageCompositionValues.map((item) => {
        let indentName = item.parentName ? `-- ${item.name}` : item.name;
            return {...item, indentName};
        });
        return dataWithIndent;
    }

    get isDisabled() {
        var done = this.bagComposition.percentageCompositionValues.filter(x => x.selectedMaterial != null).length;
        var sangleDone = this.bagComposition.sangleValues.filter(x => x.selectedMaterial != null).length;
        return done < this.bagComposition.percentageCompositionValues.filter(x => x.parentName != "").length || sangleDone < this.bagComposition.sangleValues.filter(x => x.parentName != "").length;
    }

    addCompositionToSangle() {
        var nextIndex = this.bagComposition.sangleValues.length + 1;
        var newItem = new OM.PercentageCompositionValues();
        newItem.name = "Sangle";
        newItem.name = "Composition part " +  nextIndex;
        newItem.weight = 0;

        this.bagComposition.sangleValues.push(newItem);
    }
}
