import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c584099"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "co2eEmission"
}
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_field = _resolveComponent("form-field")!
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.localShowCo2Emission)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, "(" + _toDisplayString(_ctx.co2eEmission.toFixed(4)) + " CO2e)", 1))
      : _createCommentVNode("", true),
    _createVNode(_component_form_field, {
      type: "select-string",
      labelKey: "Category",
      labelValue: "Category",
      placeholderKey: "Category",
      placeholderValue: "Category",
      list: _ctx.localCategoriesList,
      modelValue: _ctx.selectedMaterialCategory,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedMaterialCategory) = $event)),
      class: "mb-3 w-100"
    }, null, 8, ["list", "modelValue"]),
    (!_ctx.isNone)
      ? (_openBlock(), _createBlock(_component_form_field, {
          type: "select-string",
          labelKey: "Material",
          labelValue: "Material",
          placeholderKey: "Material",
          placeholderValue: "Material",
          list: _ctx.materialsForList,
          modelValue: _ctx.localSelectedMaterial,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localSelectedMaterial) = $event)),
          class: "mb-3 w-100",
          key: _ctx.rndMaterials
        }, null, 8, ["list", "modelValue"]))
      : _createCommentVNode("", true),
    (!_ctx.isNone)
      ? (_openBlock(), _createBlock(_component_form_field, {
          key: 2,
          type: "select",
          labelKey: "Country",
          labelValue: "Country",
          placeholderKey: "Country",
          placeholderValue: "Country",
          list: _ctx.allCountries,
          modelValue: _ctx.localSelectedCountry,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localSelectedCountry) = $event)),
          class: "mb-3 w-100"
        }, null, 8, ["list", "modelValue"]))
      : _createCommentVNode("", true),
    _createElementVNode("button", {
      class: "btn btn-tertiary ms-1",
      disabled: (!this.selectedMaterialCategory || !this.localSelectedCountry || !this.localSelectedMaterial) && !_ctx.isNone,
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.calculateCo2eEmission())),
      style: {"margin-bottom":"1.1rem"}
    }, [
      _createVNode(_component_localized_text, {
        localizedKey: "Save",
        text: "Save"
      })
    ], 8, _hoisted_2)
  ], 64))
}